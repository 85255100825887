import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { employeeService } from "../api/services";
import { GlobalContext, MeetingContext } from "../contexts";
import { MeetingStatus } from "../models";
import { routes } from "../../../constants/index";

export const MeetingStore: React.FC = (props) => {
  const globalContext = useContext(GlobalContext);
  const navigation = useNavigate();

  const [meetingStatus, setMeetingStatus] = useState<MeetingStatus>({} as MeetingStatus);
  const [isFetchingMeetingStatus, setIsFetchingMeetingStatus] = useState(false);
  const [isMarkingRecordingConsent, setIsMarkingRecordingConsent] = useState(false);

  const onFetchMeetingStatus = async (employeeId: string, meetingId: string) => {
    try {
      setIsFetchingMeetingStatus(true);
      const meetStatus = await employeeService.getMeetingStatus(
        employeeId,
        meetingId
      );
      setMeetingStatus(meetStatus);
    } catch (error) {
      globalContext.setAppError();
      navigation(routes.error);
    } finally {
      setIsFetchingMeetingStatus(false);
    }
  };

  const onMarkRecordingConsent = async (employeeId: string, meetingId: string) => {
    try {
      setIsMarkingRecordingConsent(true);
      const url = await employeeService.markRecordingConsent(
        employeeId,
        meetingId
      );
      return url;
    } catch (error) {
      globalContext.setAppError();
      navigation(routes.error);
    } finally {
      setIsMarkingRecordingConsent(false);
    }
  };

  return (
    <MeetingContext.Provider
      value={{
        meetingStatus: meetingStatus,
        fetchMeetingStatus: onFetchMeetingStatus,
        isFetchingMeetingStatus: isFetchingMeetingStatus,
        onMarkRecordingConsent: onMarkRecordingConsent,
        isMarkingRecordingConsent: isMarkingRecordingConsent
      }}
    >
      {props.children}
    </MeetingContext.Provider>
  );
};
