import React, { useContext } from "react";
import { QueryParamsContext } from "../../common/contexts";
import { AppContents, AppFooter, AppHeader } from ".";
import { Stack } from "@fluentui/react";

export const PageDefaultLayout: React.FC = props => {

	const queryParamsContext = useContext(QueryParamsContext);

	const renderHeader = () => {
		if (!queryParamsContext.isEmbedded) {
			return <AppHeader />;
		}

		return null;
  };

	const renderContent = () => {
		return (
			<AppContents>{props.children}</AppContents>
		);
	};

	const renderFooter = () => {
		if (!queryParamsContext.isEmbedded) {
			return <AppFooter />;
		}

		return null;
	};

	return (
		<Stack verticalAlign="space-between" styles={{ root: { minHeight: "100vh" } }}>
			<Stack.Item shrink>
				{renderHeader()}
				</Stack.Item>
			<Stack.Item grow disableShrink>
				{renderContent()}
			</Stack.Item>
			<Stack.Item shrink>
				{renderFooter()}
			</Stack.Item>
		</Stack>
	);
};
