import { Employee } from "../../models";
import { Text, Stack, IPersonaProps, Persona, PersonaPresence, PersonaSize } from "@fluentui/react";
import pl from "@messages/pl";
import { ReactComponent as CarIcon } from "../icons/CarIcon.svg";
import { ReactComponent as HomeIcon } from "../icons/HomeIcon.svg";
import { ReactComponent as TravelIcon } from "../icons/TravelIcon.svg";
import styles from "./employeeProfile.module.scss";

export interface EmployeeProfileProps {
	employee: Employee;
	hidePresence?: boolean;
};

export const EmployeeProfile: React.FC<EmployeeProfileProps> = props => {

	function RenderSpecWrapper() {
		return (
			<Stack className={styles.specIconWrapper} horizontal tokens={{ childrenGap: 8 }} verticalAlign="end">
				<CarIcon />
				<TravelIcon />
				<HomeIcon />
			</Stack>
		)
	}

	const _onRenderPrimaryText = (props?: IPersonaProps, defaultRender?: (props?: IPersonaProps) => JSX.Element | null): JSX.Element | null => {
		if (!props?.text) {
			return null;
		}
		const words = props.text.split(" ");
		return <Stack horizontal wrap>
			{words.map(w => {
				return <Text style={{ "paddingRight": "5px"}} variant="xLargePlus">{w}</Text>;
			})}
		</Stack>
	}

	const _onRenderSecondaryText = (props?: IPersonaProps, defaultRender?: (props?: IPersonaProps) => JSX.Element | null): JSX.Element | null => {
		return (
			<Stack horizontal verticalAlign="baseline" tokens={{ childrenGap: 16 }}>
				<Text variant="large">{props?.secondaryText}</Text>
				<RenderSpecWrapper />
			</Stack>
		);
	}

	return (
		<Stack horizontal>
			<Persona
				text={props.employee.displayName}
				secondaryText={pl.bookMeeting.personaSecondaryText}
				size={PersonaSize.size72}
				presence={!!props.hidePresence ? undefined : props.employee.isPresent ? PersonaPresence.online : PersonaPresence.blocked}
				imageUrl={props.employee.photo}
				onRenderPrimaryText={_onRenderPrimaryText}
				onRenderSecondaryText={_onRenderSecondaryText}
			/>
		</Stack>
	);
};

