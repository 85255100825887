import React, { FunctionComponent } from "react";
import { Stack, Text } from "@fluentui/react";
import { Checkbox } from "../checkbox/Checkbox";
import styles from "./checkboxWithLabel.module.scss";
import classNames from "classnames";


export interface CheckboxWithLabelProps {
  checked?: boolean;
  onCheckedChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  labelAsRawHtml?: boolean;
  disabled?: boolean;
  additionalClassName?: string;
}

export const CheckboxWithLabel: FunctionComponent<CheckboxWithLabelProps> = (props: CheckboxWithLabelProps) => {
  return (
    <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 20 }}>
      <Checkbox
        onCheckedChanged={props.onCheckedChanged}
        checked={props.checked}
        disabled={props.disabled}
      />
      <Text className={classNames(styles.text, props.additionalClassName)} variant="mediumPlus">
        {props.labelAsRawHtml ? <div dangerouslySetInnerHTML={{__html: props.label}} /> : props.label}
      </Text>
  </Stack>
  );
};