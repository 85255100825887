import api, { ConsentResponse } from "../generated";
import { Consent, FeatureFlags, Metadata } from "../../models";
import { ConsentTypeEnum } from "../../enums/ConsentTypeEnum";

export interface MetadataService {
    getMetadata: () => Promise<Metadata>;
}

export const metadataService: MetadataService = {
    getMetadata: async () =>  {
        const apiResult = await api.getCallCenterMetadata();
        const features : FeatureFlags = {
            callCenterEnabled: apiResult.features?.callCenterEnabled ?? false,
            callNowMeetingsEnabled: apiResult.features?.callNowMeetingsEnabled ?? false,
            acceptanceRecordingConsentBeforeJoinMeetingEnabled: apiResult.features?.acceptanceRecordingConsentBeforeJoinMeetingEnabled ?? false,
            scheduledMeetingsEnabled: apiResult.features?.scheduledMeetingsEnabled ?? false,
        }

        const consents : Consent[] = (apiResult.consents ?? []).map(c => (
            {
                content: c.content ?? "",
                consentType: c.type?.toLocaleLowerCase() === "recording" ? ConsentTypeEnum.Recording : ConsentTypeEnum.Unknown
            })
        );

        return {
            featureFlags: features,
            consents: consents            
        }
    }
};
