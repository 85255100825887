import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import {Icon, IStackTokens, ProgressIndicator, Spinner, SpinnerSize, Stack, Text} from "@fluentui/react";
import pl from "@messages/pl";
import { Button, ModalPopup, Title } from "../common/components";
import { EmployeesContext } from "../common/contexts";
import { MeetingCreationStatusEnum } from "../common/enums";
import styles from "./meetingCreationResult.module.scss";
import { useNavigate } from "react-router";
import { routes } from "../../constants";

export interface MeetingCreationResultProps {
	onClose: () => void;
}

const MeetingCreationResult: React.FC<MeetingCreationResultProps> = props => {

	const navigation = useNavigate();

	const waitIntervalRef = useRef<NodeJS.Timeout | null>(null);
	const employeesContext = useContext(EmployeesContext);
	const redirectTimeout = 8000;
	const [percentComplete, setPercentComplete] = useState(0);

	const stackTokens: IStackTokens = { childrenGap: 40 };

	const isMeetingPageUrl = () => {
		return employeesContext.meetingCreationResult.response?.meetingPageUrl
	}

	useEffect(() => {
		if (percentComplete >= 1) {
			stopCounter();
			redirectToMeetingPage();
		}
	}, [percentComplete]);

	useEffect(() => {
		if (employeesContext.meetingCreationResult.status !== MeetingCreationStatusEnum.Success) {
			return;
		}

		if (!isMeetingPageUrl()) {
			return;
		}

		waitIntervalRef.current = setInterval(() => {
			setPercentComplete((prevState) => prevState + (10 / redirectTimeout));
		}, 10);
		return () => { stopCounter() };

	}, [employeesContext.meetingCreationResult.status]);

	const stopCounter = () => {
		if (waitIntervalRef.current) {
			clearInterval(waitIntervalRef.current);
		}
	};

	const redirectToMeetingPage = () => {
		const url = employeesContext.meetingCreationResult.response?.meetingPageUrl?.replace(window.location.origin, "") ?? "";
		navigation(url);
	}

	const getIconName = (): string => {
		switch (employeesContext.meetingCreationResult.status) {
			case MeetingCreationStatusEnum.Success:
				return "CheckMark";
			case MeetingCreationStatusEnum.Conflict:
				return "Warning";
			default:
				return "ErrorBadge";
		}
	};

	const getTitle = (): string => {
		switch (employeesContext.meetingCreationResult.status) {
			case MeetingCreationStatusEnum.Success:
				return pl.meetingDetailsForm.meetingCreation.creatingMeetingIsSuccess;
			case MeetingCreationStatusEnum.Conflict:
				return pl.meetingDetailsForm.meetingCreation.creatingMeetingIsConflict;
			default:
				return pl.meetingDetailsForm.meetingCreation.creatingMeetingIsError;
		}
	};

	const goBackButton = (): ReactNode => {
		return <Button
            onClick={async () => {
              navigation(routes.root);
            }}
            inverted
            text={pl.meetingDetailsForm.backToMain}
          />
	}
	const getOpenMeetingPageButton = (): ReactNode => {
		if (
			employeesContext.meetingCreationResult.status !==
			MeetingCreationStatusEnum.Success
		) {
			return null;
		}

		return (
			<Stack
				className={styles.openMeetingPageButtonSection}
				verticalAlign="center"
				horizontalAlign="center"
			>
				<Button
					onClick={redirectToMeetingPage}
					inverted
					text={pl.meetingDetailsForm.meetingCreation.openMeetingPage}
				></Button>
				<ProgressIndicator className={styles.progress} percentComplete={percentComplete} />

			</Stack>
		);
	};

	const getDetailedMessageContent = (message: string): ReactNode => {
		return (
			<Text variant="xLarge" className={styles.detailedMessage}>
				{message}
			</Text>
		);
	};

	const getDetailedMessage = (): ReactNode => {
		switch (employeesContext.meetingCreationResult.status) {
			case MeetingCreationStatusEnum.Success:
				return (
					<>
						{getDetailedMessageContent(
							pl.meetingDetailsForm.meetingCreation.onSuccessSection1
						)}
						{getDetailedMessageContent(
							pl.meetingDetailsForm.meetingCreation.onSuccessSection2
						)}
					</>
				);
			case MeetingCreationStatusEnum.Conflict:
				return (
					<>
						{getDetailedMessageContent(
							pl.meetingDetailsForm.meetingCreation.onConflictSection1
						)}
						{getDetailedMessageContent(
							pl.meetingDetailsForm.meetingCreation.onConflictSection2
						)}
					</>
				);
			default:
				return (
					<>
						{getDetailedMessageContent(
							pl.meetingDetailsForm.meetingCreation.onErrorSection1
						)}
						{getDetailedMessageContent(
							pl.meetingDetailsForm.meetingCreation.onErrorSection2
						)}
					</>
				);
		}
	};

	const onClose = () => {
		props.onClose();
	};

	const renderCreationResult = () => {
		return (
			<Stack>
				<Stack
					className={styles.container}
					grow
					horizontal
					verticalAlign="center"
					tokens={stackTokens}
				>
					<Icon className={styles.icon} iconName={getIconName()} />
					<Stack>
						<Title additionalClassName={styles.title}  content={getTitle()} />
						{getDetailedMessage()}
					</Stack>
				</Stack>
				<Stack horizontalAlign="center">
					{isMeetingPageUrl() ? getOpenMeetingPageButton() : goBackButton()}
				</Stack>
			</Stack>
		);
	};

	if (
		employeesContext.meetingCreationResult.status ===
		MeetingCreationStatusEnum.IsPending
	) {
		return (
			<ModalPopup>
				<>
					<Stack>
						<Spinner size={SpinnerSize.large} />
						<Text variant="xLarge">
							{pl.meetingDetailsForm.meetingCreation.creatingMeetingIsPending}
						</Text>
					</Stack>
				</>
			</ModalPopup>
		);
	}

	return (
		<ModalPopup closeable onClose={onClose}>
			{renderCreationResult()}
		</ModalPopup>
	);
};
export default MeetingCreationResult;

