import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApiException } from "../api/generated";
import { employeeService } from "../api/services";
import { EmployeesContext, GlobalContext } from "../contexts";
import { MeetingCreationStatusEnum } from "../enums";
import { Employee, EmployeeAvailabilityDay, EmployeeAvailabilitySlot, MeetingCreationResult } from "../models";
import { constants, routes } from "../../../constants/index";

export const EmployeesStore: React.FC = props => {
    
    const globalContext = useContext(GlobalContext);
    const navigation = useNavigate();

    const [employees, setEmployees] = useState<Employee[]>([]);
    const [fetchingEmployees, setFetchingEmployees] = useState<boolean>(true);
    const [alreadyFetchedEmployess, setAlreadyFetchedEmployess] = useState<boolean>(false);
    const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
    const [selectedEmployeeAvailabilityDays, setSelectedEmployeeAvailabilityDays] = useState<EmployeeAvailabilityDay[]>([]);
    const [fetchingAvailabilityDays, setFetchingAvailabilityDays] = useState<boolean>(true);
    const [selectedSlot, setSelectedSlot] = useState<EmployeeAvailabilitySlot | null>(null);

    const [meetingCreationResult, setMeetingCreationResult] = useState<MeetingCreationResult>({ status: MeetingCreationStatusEnum.Unknown});

    const onFetchEmployees = async () => {
        try {
            setFetchingEmployees(true);
            const fetchedEmployees = await employeeService.getEmployees();
            setEmployees(fetchedEmployees);
        } catch (error) {
            globalContext.setAppError();
            navigation(routes.error);
        } finally {
            setFetchingEmployees(false);
            setAlreadyFetchedEmployess(true);
        }
    };

    const onCreateMeeting = async (displayName: string, phoneNumber: string, customerEmail: string) => {
        try {
            let employeeId = selectedEmployee?.id ?? constants.anyEmployeeId;
            setMeetingCreationResult({ status: MeetingCreationStatusEnum.IsPending });
            const response = await employeeService.createMeeting(employeeId, selectedSlot, displayName, phoneNumber, customerEmail);
            setMeetingCreationResult({ status: MeetingCreationStatusEnum.Success, response : response });
        } catch (error) {
            const axiosError = error as ApiException;
            if (axiosError.status === 409) {
                setMeetingCreationResult({ status: MeetingCreationStatusEnum.Conflict });
            } else {
                setMeetingCreationResult({ status: MeetingCreationStatusEnum.Error });
            }
        } finally {
        }
    }

    const onResetMeetingCreationProcess = () => {
        setMeetingCreationResult({ status: MeetingCreationStatusEnum.Unknown });
    }

    const onFetchAvailabilityDays = async (employeeId: string) => {
        try {
            setFetchingAvailabilityDays(true);
            const days = await employeeService.getAvailabilityDays(employeeId);
            setSelectedEmployeeAvailabilityDays(days);
        } catch (error) {
            globalContext.setAppError();
            navigation(routes.error);
        } finally {
            setFetchingAvailabilityDays(false);
        }
    };

    const onSetSelectedEmployee = (employee: Employee | null) => {
        setSelectedEmployee(employee);
        setSelectedSlot(null);
    }

    const resetState = () => {
        setSelectedEmployee(null);
        setSelectedSlot(null);
        onResetMeetingCreationProcess();
    }

    return (
        <EmployeesContext.Provider value={{ 
            employees: employees, 
            fetchingEmployees: fetchingEmployees,
            alreadyFetchedEmployess: alreadyFetchedEmployess,
            fetchEmployees: onFetchEmployees,

            selectedEmployee: selectedEmployee,
            setSelectedEmployee: onSetSelectedEmployee,
            
            createMeeting: onCreateMeeting,
            meetingCreationResult: meetingCreationResult,
            resetMeetingCreationProcess: onResetMeetingCreationProcess,

            fetchAvailabilityDays: onFetchAvailabilityDays,
            fetchingAvailabilityDays: fetchingAvailabilityDays,
            availabilityDays: selectedEmployeeAvailabilityDays,
            setSelectedSlot: setSelectedSlot,
            selectedSlot: selectedSlot,

            resetState: resetState
        }}>
            {props.children}
        </EmployeesContext.Provider>
    );
};
