import { IStackTokens, Stack, Icon } from "@fluentui/react";
import classNames from "classnames";
import styles from "./button.module.scss";

export interface ButtonProps {
  iconName?: string;
  text: string;
  onClick?: () => void;
  inverted?: boolean;
  disabled?: boolean;
  additionalClassName?: string;
  additionalTextClassName?: string;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const sectionStackTokens: IStackTokens = { childrenGap: 20 };
  const style = props.inverted ? styles.buttonInverted : styles.button;

  return (
    <button disabled={props.disabled} className={classNames(style, props.additionalClassName)} onClick={props.onClick}>
      <Stack
        horizontal
        horizontalAlign="center"
        verticalAlign="center"
        tokens={sectionStackTokens}
      >
        {props.iconName && (
          <Icon className={styles.icon} iconName={props.iconName} />
        )}
        <div className={classNames(styles.text, props.additionalTextClassName)} >{props.text}</div>
      </Stack>
    </button>
  );
};
