import React, { useEffect, useState } from "react";
import { Icon, IStackTokens, Spinner, SpinnerSize, Stack, Text} from "@fluentui/react";
import styles from "./joinMeeting.module.scss";
import { Button, CheckboxWithLabel, Title,
} from "../common/components";
import pl from "@messages/pl";
import { Consent, MeetingStatus } from "../common/models";
import { MeetingStatusEnum } from "../common/enums";
import { ConsentTypeEnum } from "../common/enums/ConsentTypeEnum";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/index";


export interface JoinMeetingProps {
  meetingStatus: MeetingStatus;
  loading: boolean;
  consents: Consent[];
  isMarkingRecordingConsetRequired?: boolean;
  isMarkingRecordingConsentInProgress: boolean;
  onMarkRecordingConsent: () => Promise<string | undefined>;
}

const JoinMeeting: React.FC<JoinMeetingProps> = (props) => {
  const [isRecordingConsentChecked, setIsRecordingConsentChecked] = useState(false);
  const navigation = useNavigate();

  const [msTeamsJoinMeetingUrl, setMsTeamsJoinMeetingUrl] = useState<string | undefined>("")

  useEffect(() => {
    if (!msTeamsJoinMeetingUrl) {
      setMsTeamsJoinMeetingUrl(props.meetingStatus.msTeamsJoinMeetingUrl);
    }
  }, [props.loading]);

  const stackTokens: IStackTokens = { childrenGap: 20 };
  const stackTokensProgressVsDetails: IStackTokens = { childrenGap: 60 };

  const getGraphicStatus = (): JSX.Element => {
    if (props.meetingStatus.status === MeetingStatusEnum.Creating) {
      return <Spinner size={SpinnerSize.large}></Spinner>;
    }

    let iconName = "ChromeClose";
    switch (props.meetingStatus.status) {
      case MeetingStatusEnum.ReadyToStart:
        iconName = "CheckMark";
        break;
      case MeetingStatusEnum.InProgress:
        iconName = "CheckMark";
        break;
      default:
        break;
    }

    return <Icon className={styles.statusIcon} iconName={iconName} />;
  };

  const getTitle = (): string => {
    switch (props.meetingStatus.status) {
      case MeetingStatusEnum.Creating:
        return pl.meetingPage.meetingStatus.creating;
      case MeetingStatusEnum.Ended:
        return pl.meetingPage.meetingStatus.ended;
      case MeetingStatusEnum.InProgress:
        return pl.meetingPage.meetingStatus.inProgress;
      case MeetingStatusEnum.ReadyToStart:
        return pl.meetingPage.meetingStatus.readyToStart;
      case MeetingStatusEnum.Canceled:
        return pl.meetingPage.meetingStatus.canceled;
      default:
        return pl.meetingPage.meetingStatus.unknown;
    }
  };

  const detailsMessage = (message: string): JSX.Element => {
    return (
      <Text variant="xLarge" className={styles.detailedMessage}>
        {message}
      </Text>
    );
  };

  const getScheduleMessage = (): JSX.Element | null => {
    if (
      props.meetingStatus.status !== MeetingStatusEnum.Creating &&
      props.meetingStatus.status !== MeetingStatusEnum.InProgress &&
      props.meetingStatus.status !== MeetingStatusEnum.ReadyToStart
    ) {
      return null;
    }

    if (props.meetingStatus.officialMeetingTime?.start) {
      return detailsMessage(
        pl.meetingPage.meetingScheduledTo(
          props.meetingStatus.officialMeetingTime!.start!
        )
      );
    }

    return null;
  };

  const getJoinFromMessage = (): JSX.Element | null => {
    if (
      props.meetingStatus.status !== MeetingStatusEnum.InProgress &&
      props.meetingStatus.status !== MeetingStatusEnum.ReadyToStart
    ) {
      return null;
    }

    if (
      props.meetingStatus.allowJoiningEarlier &&
      props.meetingStatus.allowedJoinTime?.start
    ) {
      return detailsMessage(
        pl.meetingPage.joinMeetingAllowedFrom(
          props.meetingStatus.allowedJoinTime!.start!
        )
      );
    }
    return null;
  };

  const getRecordingConsent = () => {
    return props.consents.find((c) => c.consentType === ConsentTypeEnum.Recording)?.content;
  };

  const renderOpenMeetingButtonWithConsent = () => {
    if (
      props.meetingStatus.status !== MeetingStatusEnum.ReadyToStart &&
      props.meetingStatus.status !== MeetingStatusEnum.InProgress
    ) {
      return null;
    }

    if (!props.meetingStatus.allowedJoinTime?.start || props.meetingStatus.allowedJoinTime.start > new Date()) {
      return null;
    }

    if (props.loading) {
      return null;
    }

    return (
      <Stack
        tokens={{ childrenGap: 20 }}
      >
        {props.isMarkingRecordingConsetRequired &&
          <CheckboxWithLabel
            additionalClassName={styles.consentLabel}
            onCheckedChanged={() => {
              setIsRecordingConsentChecked(!isRecordingConsentChecked);
            }}
            disabled={getRecordingConsent() === undefined}
            checked={isRecordingConsentChecked}
            label={getRecordingConsent() ?? ""}
            labelAsRawHtml={true}
          />
        }

        <Stack.Item
          align="center"
          styles={{ root: { width: "380px", textAlign: "center" } }}
        >
          <Button
            onClick={async () => {
              let urlToOpen = msTeamsJoinMeetingUrl;
              if (props.isMarkingRecordingConsetRequired && !msTeamsJoinMeetingUrl) {
                urlToOpen = await props.onMarkRecordingConsent();
                setMsTeamsJoinMeetingUrl(urlToOpen);
              }
              window.open(urlToOpen, "_blank");

            }}
            disabled={props.isMarkingRecordingConsentInProgress || (props.isMarkingRecordingConsetRequired && !isRecordingConsentChecked)}
            inverted
            text={pl.meetingPage.openMeeting}
            iconName={props.isMarkingRecordingConsentInProgress ? "ProgressRingDots" : "VideoSolid"}
          />
        </Stack.Item>
      </Stack>
    );
  };

  const renderLoader = () => {
    return <Spinner size={SpinnerSize.large} />;
  };

  const renderStatus = () => {
    return (
      <Stack horizontal tokens={{ childrenGap: 40 }}>
        {getGraphicStatus()}
        <Stack>
          <Title additionalClassName={styles.textSecondaryColor} content={getTitle()} />
          {getScheduleMessage()}
          {getJoinFromMessage()}
        </Stack>
      </Stack>
    );
  };

  return (
    <Stack
      className={styles.container}
      horizontalAlign="center"
      verticalAlign="center"
      tokens={stackTokens}
    >
      <Stack horizontal wrap>
        <Title additionalClassName={styles.title} large content={pl.meetingDetailsForm.headerPart1}></Title>
        <Title additionalClassName={styles.titleAccent} large content={pl.meetingDetailsForm.headerPart2}></Title>
      </Stack>

      <Stack
        wrap
        horizontalAlign="center"
        className={styles.statusCard}
        tokens={stackTokensProgressVsDetails}
      >
        {props.loading ? renderLoader() : renderStatus()}

        {renderOpenMeetingButtonWithConsent()}
      </Stack>
      {(props.meetingStatus.status === MeetingStatusEnum.Ended || props.meetingStatus.status === MeetingStatusEnum.Canceled)
        && <Button
            onClick={async () => {
              navigation(routes.root);
            }}
            inverted
            text={pl.meetingPage.scheduleAnotherMeeting}
          />}
    </Stack>
  );
};

export default JoinMeeting;
