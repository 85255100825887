import React from "react";
import { MeetingStatusEnum } from "../enums";
import { MeetingStatus } from "../models";

export interface MeetingContextProps {
    meetingStatus : MeetingStatus;
    
    fetchMeetingStatus: (employeeId: string, meetingId: string) => Promise<void>;
    isFetchingMeetingStatus: boolean;
    
    onMarkRecordingConsent: (employeeId: string, meetingId: string) => Promise<string | undefined>;
    isMarkingRecordingConsent: boolean;
}

export const defaultMeetingContext: MeetingContextProps = {
    meetingStatus: { status: MeetingStatusEnum.Unknown} as MeetingStatus,
    
    fetchMeetingStatus : (employeeId: string, meetingId: string) => new Promise<void>(_ => null),
    isFetchingMeetingStatus: false,

    onMarkRecordingConsent : (employeeId: string, meetingId: string) => new Promise<string | undefined>(_ => null),
    isMarkingRecordingConsent: false,

};

export const MeetingContext = React.createContext<MeetingContextProps>(defaultMeetingContext);
