import React from "react";
import { Employee, EmployeeAvailabilityDay, EmployeeAvailabilitySlot, MeetingCreationResult } from "../models";
import { MeetingCreationStatusEnum } from "../enums/";

export interface EmployeesContextProps {
    employees : Employee[];
    selectedEmployee: Employee | null;
    setSelectedEmployee: (employee: Employee | null) => void;

    fetchingEmployees: boolean,
    alreadyFetchedEmployess: boolean,
    fetchEmployees: () => Promise<void>;
    
    createMeeting: (displayName: string, phoneNumber: string, customerEmail: string) => Promise<void>,
    meetingCreationResult: MeetingCreationResult,

    resetMeetingCreationProcess: () => void,

    fetchAvailabilityDays: (employeeId: string) => Promise<void>;
    fetchingAvailabilityDays: boolean;
    availabilityDays: EmployeeAvailabilityDay[];
    setSelectedSlot: (slot: EmployeeAvailabilitySlot) => void;
    selectedSlot: EmployeeAvailabilitySlot | null;

    resetState: () => void;
}

export const EmployeesContext = React.createContext<EmployeesContextProps>({ 
    employees: [], 
    selectedEmployee: null,
    setSelectedEmployee: _ => {},

    fetchingEmployees: false,
    alreadyFetchedEmployess: false,
    fetchEmployees: () => new Promise<void>(_ => null),

    createMeeting: _ => new Promise<void>(_ => null),
    meetingCreationResult: { status: MeetingCreationStatusEnum.Unknown },
    
    resetMeetingCreationProcess: () => {},
    fetchAvailabilityDays: () => new Promise<void>(_ => null),
    fetchingAvailabilityDays: false,
    availabilityDays: [],
    setSelectedSlot: _ => {},
    selectedSlot: {} as EmployeeAvailabilitySlot,

    resetState: () => null,
});
