import { useContext, useState } from "react";
import { Icon, IStackTokens, Stack, Text } from "@fluentui/react";
import pl from "@messages/pl";
import { Button, Title, ValidationInput, EmployeeProfile, CheckboxWithLabel } from "../common/components";
import { Employee, EmployeeAvailabilitySlot } from "../common/models";
import styles from "./meetingDetailsForm.module.scss";
import * as validationUtils from "../../utils/validationUtils";
import MeetingDataItem from "./MeetingDataItem";
import { EmployeesContext } from "../common/contexts";
import { MeetingCreationStatusEnum } from "../common/enums";
import MeetingCreationResult from "./MeetingCreationResult";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/index";
import * as dateUtils from "../../utils/dateUtils";

export interface MeetingDetailsFormProps {
  selectedEmployee: Employee | null;
  startDate?: Date;
  selectedSlot: EmployeeAvailabilitySlot | null;
}

const MeetingDetailsForm: React.FC<MeetingDetailsFormProps> = (props) => {
	const [isConsentChecked, setIsConsentChecked] = useState(false);
	const [customerFirstName, setCustomerFirstName] = useState("");
	const [customerLastName, setCustomerLastName] = useState("");
	const [customerEmail, setCustomerEmail] = useState("");
	const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");

	const employeesContext = useContext(EmployeesContext);
	const navigation = useNavigate();

	const boxStackTokens: IStackTokens = { childrenGap: 20 };
	const consentStackTokens: IStackTokens = { childrenGap: 20 };

	const renderEmployeeProfile = (): JSX.Element => {
		return <EmployeeProfile employee={props.selectedEmployee!} hidePresence />;
	};

	const validateAllFields = () => {
		if (
			customerFirstName.length &&
			customerLastName.length &&
			customerEmail.length &&
			customerPhoneNumber.length
		) {
			return (
				isConsentChecked &&
				validationUtils.validateEmail(customerEmail) &&
				validationUtils.validatePhoneNumber(customerPhoneNumber)
			);
		}

		return false;
	};

	const renderMeetingDate = (): JSX.Element => {
		const stackTockens: IStackTokens = { childrenGap: 20 };

		return (
			<Stack horizontal tokens={stackTockens} verticalAlign="center">
				<Icon className={styles.meetingDateIcon} iconName="Calendar" />
				{props.selectedSlot &&
					<Stack verticalAlign="center" tokens={{ childrenGap: 4 }}>
						<Text variant="xLarge"  >{dateUtils.toLocalizedDate(props.selectedSlot.startAt)}</Text>
						<Stack horizontal tokens={{ childrenGap: 6 }} >
							<Text variant="large">{pl.meetingDetailsForm.dayOfTheWeek(props.selectedSlot.startAt)}</Text>
							<Text variant="large">{pl.meetingDetailsForm.startMeetingHour(props.selectedSlot.startAt)}</Text>
						</Stack>
					</Stack>}
				{!props.selectedSlot &&
					<Text variant="xLarge">{pl.meetingDetailsForm.meetNow}</Text>
				}
			</Stack>
		);
	};

	const renderForm = (): JSX.Element => {
		return (
			<>
				<Stack horizontalAlign="center" tokens={boxStackTokens}>
					<Stack horizontalAlign="center" horizontal wrap>
						<Title additionalClassName={styles.title} large content={pl.meetingDetailsForm.headerPart1}></Title>
						<Title additionalClassName={styles.titleAccent} large content={pl.meetingDetailsForm.headerPart2}></Title>
					</Stack>

          <Stack className={styles.formWrapper} tokens={boxStackTokens}>
            {props.selectedEmployee &&
              <MeetingDataItem
                onActionButtonClick={onEmployeeChange}
                isActionButtonDisabled={false}
                meetingDataNode={renderEmployeeProfile}
              ></MeetingDataItem>
            }

						<MeetingDataItem
							onActionButtonClick={onMeetingTypeChange}
							isActionButtonDisabled={false}
							meetingDataNode={renderMeetingDate}
						></MeetingDataItem>

						<ValidationInput
							id="first-name-field"
							placeholder={pl.meetingDetailsForm.firstName}
							value={customerFirstName}
							handleDataChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => {
								setCustomerFirstName(event.target.value);
							}}
						/>
						<ValidationInput
							id="last-name-field"
							placeholder={pl.meetingDetailsForm.lastName}
							value={customerLastName}
							handleDataChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => {
								setCustomerLastName(event.target.value);
							}}
						/>
						<ValidationInput
							id="email-field"
							isInvalid={!validationUtils.validateEmail(customerEmail)}
							placeholder={pl.meetingDetailsForm.email}
							errorMessage={pl.meetingDetailsForm.validation.wrongEmail}
							value={customerEmail}
							handleDataChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => {
								setCustomerEmail(event.target.value);
							}}
						/>
						<ValidationInput
							id="phone-number-field"
							isInvalid={
								!validationUtils.validatePhoneNumber(customerPhoneNumber)
							}
							placeholder={pl.meetingDetailsForm.phoneNumber}
							errorMessage={pl.meetingDetailsForm.validation.wrongPhoneNumber}
							value={customerPhoneNumber}
							handleDataChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => {
								setCustomerPhoneNumber(event.target.value);
							}}
						/>
						<Stack horizontal tokens={consentStackTokens}>
							<CheckboxWithLabel
								onCheckedChanged={() => {
									setIsConsentChecked(!isConsentChecked);
								}}
								checked={isConsentChecked}
								label={pl.meetingDetailsForm.regulationsConsent}
							/>
						</Stack>
						<Button
							disabled={!validateAllFields()}
							onClick={async () => {
								await employeesContext.createMeeting(
									`${customerFirstName} ${customerLastName}`,
									customerPhoneNumber,
									customerEmail
								);
							}}
							inverted
							text={pl.meetingDetailsForm.createMeeting}
							iconName="VideoSolid"
						/>
					</Stack>
				</Stack>
				{employeesContext.meetingCreationResult.status !==
					MeetingCreationStatusEnum.Unknown && (
						<MeetingCreationResult onClose={onClose} />
					)}
			</>
		);
	};

	const onEmployeeChange = () => {
		navigation(routes.root);
	}

	const onMeetingTypeChange = () => {
		navigation(-1);
	}

	const onClose = () => {
		employeesContext.resetMeetingCreationProcess();
		navigation(routes.root);
	};

	return renderForm();
};

export default MeetingDetailsForm;
