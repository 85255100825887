import { useContext, useEffect } from "react";
import { EmployeesContext } from "../common/contexts";
import MeetingDetailsForm from "../meetingDetailsForm/MeetingDetailsForm";
import { useNavigate } from "react-router-dom";
import { routes } from "../../constants/index";

export const MeetingDetailsFormPage: React.FC = () => {
	const employeesContext = useContext(EmployeesContext);
	const navigation = useNavigate();


  return (
    <>
        <MeetingDetailsForm
          selectedEmployee={employeesContext.selectedEmployee}
          selectedSlot={employeesContext.selectedSlot}
        />
    </>
  );
};
