import { Spinner, SpinnerSize, Stack } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import BookMeeting from "../bookMeeting/BookMeeting";
import { EmployeesContext } from "../common/contexts";

export const BookMeetingPage: React.FC = () => {

    const employeesContext = useContext(EmployeesContext);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchEmployees = async () => {
            employeesContext.resetState();
            await employeesContext.fetchEmployees();
            setLoading(false);
        };

        fetchEmployees();
    }, []);

	if (loading) {
		return (
			<Stack styles={{root: {minHeight: '60vh'}}} verticalAlign="center">
				<Spinner size={SpinnerSize.large} />
			</Stack>
		);
	}

    return (
        <BookMeeting employees={employeesContext.employees} loading={employeesContext.fetchingEmployees} />
    );
};
