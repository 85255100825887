import * as dateUtils from "../../../utils/dateUtils";
import { IMessages } from "../IMessages";

const nbsp: string = "\u00A0";

const pl : IMessages = {
    common: {
        defaultAppErrorMessage: "Coś poszło nie tak...",
        save: "Zapisz",
        ok: "OK",
        pageNotFound: "Nie znaleziono tej strony"
    },
    bookMeeting: {
        header: "Znajdź nowe ubezpieczenie z ecom Digital Agents",
        subheader1: `Skorzystaj z bezpłatnej${nbsp}`,
        subheader2: `porady online`,
        callCenterNotEnabled: "Obecnie umówienie spotkania z konsultantem nie jest możliwe",
        noCallCenterEmployees: "Obecnie umówienie spotkania z konsultantem nie jest możliwe",
        personaSecondaryText: "Digital Agent",
        startOnlineMeeting: "Rozpocznij spotkanie online",
        scheduleMeeting: "Umów spotkanie",
        select: "Wybierz",
        selectSchedulingDay: "Wybierz dzień spotkania",
        selectSchedulingSlot: "Wybierz godzinę spotkania",
        noSlotsAvailable: "Brak dostępnych slotów",
        chooseMeetingDate: "Wybierz termin spotkania online"
    },
    footer: {
        companyMark: "ecom software 2022",
        regulations: "Regulamin usługi",
        regulationsLink: "https://ecom.software"
    },
    meetingPage: {
        headerPart1: `Twoja bezpłatna${nbsp}`,
        headerPart2: `porada online`,
        meetingStatus: {
            unknown: "Nie mamy informacji o tym spotkaniu",
            creating: "Przygotowujemy Twoje spotkanie...",
            readyToStart: "Twoje spotkanie jest gotowe",
            inProgress: "Twoje spotkanie jest gotowe",
            ended: "Twoje spotkanie już się zakończyło",
            canceled: "Twoje spotkanie zostało odwołane"
        },
        meetingScheduledTo: (date: Date) =>
            `Twoje spotkanie jest zaplanowane na ${dateUtils.getTeamsTimeFormat(date)} ${dateUtils.getTeamsDateFormat(date)}`,
        joinMeetingAllowedFrom : (date: Date) => {
            return `Możesz na nie dołączyć od ${dateUtils.getTeamsTimeFormat(date)}`
        },
        openMeeting: "Otwórz spotkanie",
        scheduleAnotherMeeting: "Umów się na kolejne spotkanie"
    },
    meetingDetailsForm: {
        headerPart1: `Twoja bezpłatna${nbsp}`,
        headerPart2: `porada online`,
        meetNow: "Umów jak najszybciej",
        meetingDataItem: {
            change: "zmień",
        },
        dayOfTheWeek: (date: Date) => `${dateUtils.toLocalizedWeekday(date)},`,
        startMeetingHour: (date: Date) => `godzina ${dateUtils.toLocalizedHourMinute(date)}`,
        firstName: "Imię",
        lastName: "Nazwisko",
        email: "Adres e-mail",
        phoneNumber: "Telefon",
        regulationsConsent:
            "Akceptuję regulamin usługi darmowej porady online oraz zasady przetwarzania danych osobowych",
        meetingCreation: {
            creatingMeetingIsPending: "Trwa tworzenie porady...",
            creatingMeetingIsSuccess: "Porada zarezerwowana",
            creatingMeetingIsConflict: "Wybrany Agent jest zajęty",
            creatingMeetingIsError: "Trwa tworzenie porady zakończyło się błędem",
            onSuccessSection1: "Potwierdzenie rezerwacji wysłaliśmy także na Twój adres email.",
            onSuccessSection2: "Do zobaczenia!",
            onConflictSection1: "Wybrany Agent jest niedostępny lub ma w tym czasie inne spotkanie.",
            onConflictSection2: "Wybierz innego Agenta lub spróbuj ponownie.",
            onErrorSection1: "Wystąpił błąd podczas tworzenia porady.",
            onErrorSection2: "Wybierz innego Agenta lub spróbuj ponownie.",
            openMeetingPage: "Otwórz stronę spotkania",
        },
        createMeeting: "Umów poradę",
        validation: {
            wrongEmail: "Podany adres email jest nieprawidłowy",
            wrongPhoneNumber: "Podany numer telefonu jest nieprawidłowy",
        },
        finish: "Zakończ",
        confirmMeetingMessage: `Aby potwierdzić poradę, prosimy o${nbsp}kliknięcie w${nbsp}link przesłany na${nbsp}adres e-mail w${nbsp}ciągu 15${nbsp}minut.`,
        backToMain: "Powrót na stronę główną"
    },
};

export default pl;