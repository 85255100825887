import api, { CreateScheduledMeetingRequest, GetCallCenterEmployeeAvailabilitySlotResponse, GetCallCenterEmployeesResponse, GetMeetingStatusResponseTime, MarkMeetingRecordingConsentRequest } from "../generated";
import { Employee, EmployeeAvailabilityDay, EmployeeAvailabilitySlot, MeetingCreationResponse, MeetingStatus, TimeRange } from "../../models";
import { MeetingStatusEnum } from "../../enums";
import * as arrayUtils from "../../../../utils/arrayUtils";

export interface EmployeesService {
    getEmployees: () => Promise<Employee[]>;
    createMeeting: (employeeId: string, slot: EmployeeAvailabilitySlot | null, displayName: string, phoneNumber:string, customerEmail: string) => Promise<MeetingCreationResponse>;
    getMeetingStatus: (employeeId: string, meetingId: string) => Promise<MeetingStatus>;
    markRecordingConsent: (employeeId: string, meetingId: string) => Promise<string | undefined>;
    getAvailabilityDays: (employeeId: string) => Promise<EmployeeAvailabilityDay[]>;
}

export const employeeService: EmployeesService = {

    getEmployees: async () =>  {
        const apiResult = await api.getCallCenterEmployees();
        const models = apiResult.map((dto :GetCallCenterEmployeesResponse) :  Employee => ({
            id: dto.id ?? "",
            isPresent: dto.isPresent ?? false,
            displayName: dto.displayName ?? "",
            photo: dto.photo,
            isPresentForCalNowMeetings: dto.isPresentForCalNowMeetings ?? false,
            availabilitySlots: dto.availabilitySlots?.map((s : GetCallCenterEmployeeAvailabilitySlotResponse) : EmployeeAvailabilitySlot  => ({ startAt: s.startAt!, endAt: s.endAt! })) ?? []

        }));
        return models;
    },

    createMeeting: async (employeeId: string, slot: EmployeeAvailabilitySlot | null, displayName: string, phoneNumber:string, customerEmail: string)  : Promise<MeetingCreationResponse> => {
        const request = new CreateScheduledMeetingRequest({
            displayName: displayName,
            phoneNumber: phoneNumber,
            email: customerEmail,
            isCallNow: slot === null,
            startAt: slot?.startAt,
            endAt: slot?.endAt
        });
        const apiResult = await api.createScheduledMeetingFunction(employeeId, request);
        return {
            employeeId: apiResult.employeeId,
            meetingId: apiResult.meetingId,
            meetingPageUrl: apiResult.meetingPageUrl
        } as MeetingCreationResponse;
    },

    getMeetingStatus: async (employeeId: string, meetingId: string): Promise<MeetingStatus> => {

        const statusToStatusEnum = (text: string | undefined) : MeetingStatusEnum => {
            if (text === undefined || !text) {
                return MeetingStatusEnum.Unknown;
            }

            switch(text.toLowerCase()) {
                case "creating":
                    return MeetingStatusEnum.Creating;
                case "readytostart":
                    return MeetingStatusEnum.ReadyToStart;
                case "inprogress":
                    return MeetingStatusEnum.InProgress;
                case "ended":
                    return MeetingStatusEnum.Ended;
                case "canceled":
                    return MeetingStatusEnum.Canceled;
                default:
                    return MeetingStatusEnum.Unknown;
            }
        }

        const mapApiTimeRange = (range: GetMeetingStatusResponseTime | undefined) : TimeRange | undefined => {
            if (range === undefined) {
                return undefined;
            }
            
            return {
                start: range.startAt,
                end: range.endAt 
            } as TimeRange;
        }

        const apiResutl = await api.getMeetingStatus(employeeId, meetingId);

        return {
            status: statusToStatusEnum(apiResutl.status),
            officialMeetingTime: mapApiTimeRange(apiResutl.officialMeetingTime),
            allowJoiningEarlier: apiResutl.allowJoiningEarlier,
            allowedJoinTime: mapApiTimeRange(apiResutl.allowedJoinTime),
            allowJoiningXMinutesBeforeOfficialStartTime: apiResutl.allowJoiningXMinutesBeforeOfficialStartTime,
            msTeamsJoinMeetingUrl: apiResutl.msTeamsJoinMeetingUrl
        };
    },

    markRecordingConsent: async (employeeId: string, meetingId: string)  : Promise<string | undefined> => {
        const apiResult = await api.markMeetingRecordingConsent(employeeId, meetingId, new MarkMeetingRecordingConsentRequest());
        return apiResult?.msTeamsJoinMeetingUrl;
    },

    getAvailabilityDays: async (employeeId: string)  : Promise<EmployeeAvailabilityDay[]> => {
        const apiResult = await api.getEmployeeAvailabilitySlotsFunction(employeeId);
        const apiResultGrouped = arrayUtils.groupBy(apiResult, r => r.startAt?.getDate())
        const result :EmployeeAvailabilityDay[] = [];
        apiResultGrouped.forEach((v: EmployeeAvailabilitySlot[], k) => {
            result.push({
                day: v[0].startAt,
                slots: v
            })
        })
        return result;
    },
};
