import React from "react";
import { GlobalStore, EmployeesStore, MetadataStore, MeetingStore, QueryParamsStore } from "./";

export const Stores: React.FC = (props) => (
  <QueryParamsStore>
    <GlobalStore>
      <MetadataStore>
        <EmployeesStore>
          <MeetingStore>{props.children}</MeetingStore>
        </EmployeesStore>
      </MetadataStore>
    </GlobalStore>
  </QueryParamsStore>
);
