import React, { useState } from "react";
import { QueryParamsContext } from "../contexts";
import { useLocation } from "react-router-dom";

export const QueryParamsStore: React.FC = props => {
    
    const [isEmbedded, setEmbedded] = useState<boolean>(false);
    const location = useLocation();

    const setQueryContext = () => {
        const isEmbedded = new URLSearchParams(location.search).get('emb');
        if (isEmbedded && isEmbedded.toLocaleLowerCase() === "true") {
            setEmbedded(true);
        }
    }
    return (

        <QueryParamsContext.Provider value={{ 
            isEmbedded: isEmbedded,
            setQueryContext: setQueryContext
        }}>
            {props.children}
        </QueryParamsContext.Provider>
    );
};
