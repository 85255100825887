import React from "react";
import { Text } from "@fluentui/react";
import classNames from "classnames";
import styles from "./validationInput.module.scss";
import { ValidationInputProps } from "./ValidationInput.types";

export const ValidationInput: React.FunctionComponent<ValidationInputProps> = (
  props: ValidationInputProps
) => {

  return (
    <div className={classNames(styles.wrapper, props.additionalClassName)}>
        <div className={styles.contentWrapper}>
          <div className={styles.inputWrapper}>
            <input
              type={props.type || "text"}
              className={classNames(styles.input, props.additionalInputClassName)}
              id={props.id}
              value={props.value}
              placeholder={props.placeholder}
              onChange={props.handleDataChange}
            />
          </div>
        {props.value && props.isInvalid && (
          <Text className={styles.error}>{props.errorMessage}</Text>
        )}
      </div>
    </div>
  );
};

