import { useContext } from "react";
import EmployeeSelection from "./EmployeeSelection";
import { Employee } from "../common/models";
import { EmployeesContext } from "../common/contexts";
import { useNavigate } from "react-router-dom";
import { constants, routeParams, routes } from "../../constants/index";

export interface BookMeetingProps {
  employees: Employee[];
  loading: boolean;
}

const BookMeeting: React.FC<BookMeetingProps> = (props) => {
  const employeesContext = useContext(EmployeesContext);

  const navigation = useNavigate();

  const onCallNowScheduleMeeting = () => {
    employeesContext.setSelectedEmployee(null);
    const url = routes.meetingDetailsPage.replace(routeParams.employeeId, constants.anyEmployeeId);
    navigation(url);
  };

  const onScheduleMeeting = (employee: Employee) => {
    employeesContext.setSelectedEmployee(employee);
    
    const url = routes.employeeSchedule.replace(routeParams.employeeId, employee.id);
    navigation(url);
  };

  return <EmployeeSelection {...props} onCallNowMeeting={onCallNowScheduleMeeting} onScheduleMeeting={onScheduleMeeting} />;
};

export default BookMeeting;
