import { validation } from "../constants";

export function validateEmail(email: string) {
  if (!email.length) {
    return true;
  }

  return validation.emailRegExp.test(email);
}

export function validatePhoneNumber(phoneNumber: string) {
  if (!phoneNumber.length) {
    return true;
  }

  return validation.phoneNumberRegExp.test(phoneNumber);
}

export function validateCityName(cityName: string) {
  return validation.cityNameRegExp.test(cityName);
}
