import { Stack, Spinner, SpinnerSize, Layer } from "@fluentui/react";
import styles from "./GlobalLoader.module.scss";

export interface GlobalLoaderProps {
    visible: boolean;
    loadingMessage?: string
};

const GlobalLoader: React.FC<GlobalLoaderProps> = props => {

    const renderGlobalLoader = () => {
        if (props.visible) {
          return (
            <Layer insertFirst>
                <Stack className={styles.globalLoader} verticalAlign="center" horizontalAlign="center">
                    <Spinner size={SpinnerSize.large} label={props.loadingMessage} />
                </Stack>
            </Layer>
          );
        }

        return null;
    };

    return (
        <>
            {props.children}
            {renderGlobalLoader()}
        </>
    );
};

export default GlobalLoader;