import { Stack, Text } from "@fluentui/react";
import styles from "./GlobalError.module.scss";

export interface GlobalErrorProps {
    text: string;
};

const GlobalError: React.FC<GlobalErrorProps> = props => (
    <Stack verticalAlign="center" horizontalAlign="center">
        <Text className={styles.text} variant="xxLarge">{props.text}</Text>
    </Stack>
);

export default GlobalError;