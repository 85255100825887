import React, { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { MetadataContext, defaultMetadataContext, GlobalContext } from "../contexts";
import { Metadata } from "../models";
import { routes } from "../../../constants/index";
import { metadataService } from "../api/services";

export const MetadataStore: React.FC = props => {
    
    const globalContext = useContext(GlobalContext);
    const navigation = useNavigate();

    const [metadata, setMetadata] = useState<Metadata>(defaultMetadataContext.metadata);
    const [fetchingMetadata, setFetchingMetadata] = useState<boolean>(true);

    const onFetchMetadata = async () => {
        try {
            globalContext.setAppLoading(true);
            setFetchingMetadata(true);
            const metadata = await metadataService.getMetadata();
            setMetadata(metadata);
        } catch (error) {
            globalContext.setAppError();
            navigation(routes.error);
        } finally {
            setFetchingMetadata(false);
            globalContext.setAppLoading(false);
        }
    };

    return (
        <MetadataContext.Provider value={{
            metadata: metadata,
            fetchingMetadata: fetchingMetadata,
            fetchMetadata: onFetchMetadata
        }}>
            {props.children}
        </MetadataContext.Provider>
    );
};
