import React, { useContext, useEffect, useState } from "react";
import { DatePicker, DayOfWeek, Dropdown, Icon, IDropdownOption, Spinner, SpinnerSize, Stack, Text } from "@fluentui/react";
import { EmployeesContext, MetadataContext } from "../common/contexts";
import GlobalNotFound from "../global/not-found/GlobalNotFound";
import AvailableEmployeeCard, { AvailableEmployeeCardActions } from "../bookMeeting/AvailableEmployeeCard";
import { Employee } from "../common/models";
import { useNavigate } from "react-router";
import { routeParams, routes } from "../../constants";
import { ICalendarStrings } from "@fluentui/date-time-utilities";
import pl from "@messages/pl";
import styles from "./ScheduleMeetingStartingWithDaySelectionPage.module.scss";

//TODO: Mechanizm tłumaczenia DatePicker
const datepickerStrings: ICalendarStrings = {
	goToToday: "",
	months: [
		"Styczeń",
		"Luty",
		"Marzec",
		"Kwiecień",
		"Maj",
		"Czerwiec",
		"Lipiec",
		"Sierpień",
		"Wrzesień",
		"Październik",
		"Listopad",
		"Grudzień"
	],
	shortMonths: [
		"Sty",
		"Lut",
		"Mar",
		"Kwi",
		"Maj",
		"Cze",
		"Lip",
		"Sie",
		"Wrz",
		"Paź",
		"Lis",
		"Gru"
	],
	days: [
		"Niedziela",
		"Poniedziałek",
		"Wtorek",
		"Środa",
		"Czwartek",
		"Piątek",
		"Sobota"
	],
	shortDays: [
		"Ndz",
		"Pon",
		"Wtr",
		"Śrd",
		"Czw",
		"Pią",
		"Sob"
	]
};

export const ScheduleMeetingStartingWithDaySelectionPage: React.FC = () => {

	const metadataContext = useContext(MetadataContext);
	const employeesContext = useContext(EmployeesContext);

	const navigate = useNavigate();

	const [pageIsLoading, setPageIsLoading] = useState<boolean>(true);
	const [selectedDay, setSelectedDay] = useState<Date>();
	const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>();

	useEffect(() => {
		const initialize = async () => {
			if (employeesContext.alreadyFetchedEmployess === false) {
				await employeesContext.fetchEmployees();
			}
			if (employeesContext.selectedSlot) {
				const previouslySelectedDay = new Date(employeesContext.selectedSlot.startAt);
				previouslySelectedDay.setHours(0, 0, 0, 0);
				const previouslySelectedSlot = `${employeesContext.selectedSlot.startAt.toString()}_${employeesContext.selectedSlot.endAt.toString()}`;
				setSelectedDay(previouslySelectedDay);
				setSelectedTimeSlot(previouslySelectedSlot);
			}
			setPageIsLoading(false);
		};

		initialize();
	}, []);

	const scheduleMeetingsFeatureFlagIsDisabled = metadataContext.metadata.featureFlags.scheduledMeetingsEnabled === false;
	if (scheduleMeetingsFeatureFlagIsDisabled) {
		return <GlobalNotFound />;
	}

	if (pageIsLoading) {
		return (
			<Stack styles={{ root: { minHeight: '60vh' } }} verticalAlign="center">
				<Spinner size={SpinnerSize.large} />
			</Stack>
		);
	}

	const thereIsNoAvailabilitySlotsPresentForAnyEmployee = employeesContext.employees.some(employee => employee.availabilitySlots.length > 0) === false;
	if (thereIsNoAvailabilitySlotsPresentForAnyEmployee) {
		return (
			<Stack horizontalAlign="center">
				<Text style={{color: "white"}} variant="large">{pl.bookMeeting.noSlotsAvailable}</Text>;
			</Stack>
		);
	}

	// day -> start time -> available employeeIds
	const availabilitySlots: { day: Date, startTime: Date, endTime: Date, employeeId: string }[] = [];
	employeesContext.employees.forEach(employee => {
		employee.availabilitySlots.forEach(slot => {
			const day = new Date(slot.startAt);
			day.setHours(0, 0, 0, 0);
			availabilitySlots.push({
				day: day,
				startTime: slot.startAt,
				endTime: slot.endAt,
				employeeId: employee.id
			});
		});
	});

	availabilitySlots.sort((a, b) => {
		return a.day.getTime() - b.day.getTime();
	}).sort((a, b) => {
		return a.startTime.getTime() - b.startTime.getTime();
	});

	const availabilitySlotsDictionary: Map<string, Map<string, string[]>> = new Map<string, Map<string, string[]>>();
	availabilitySlots.forEach(availabilitySlot => {
		const dayString = availabilitySlot.day.toString();
		const startTimeString = availabilitySlot.startTime.toString();
		const endTimeString = availabilitySlot.endTime.toString();
		const existingDayEntry = availabilitySlotsDictionary.get(dayString);
		const timeSlot = `${startTimeString}_${endTimeString}`;
		if (existingDayEntry) {
			const existingTimeEntry = existingDayEntry.get(timeSlot);
			if (existingTimeEntry) {
				const employeeIdEntryAlreadyExists = existingTimeEntry.some(employeeId => employeeId === availabilitySlot.employeeId) === true;
				if (employeeIdEntryAlreadyExists === false) {
					existingTimeEntry.push(availabilitySlot.employeeId);
				}
			} else {
				existingDayEntry.set(timeSlot, []);
				existingDayEntry.get(timeSlot)?.push(availabilitySlot.employeeId);
			}
		} else {
			availabilitySlotsDictionary.set(dayString, new Map<string, string[]>());
			availabilitySlotsDictionary.get(dayString)?.set(timeSlot, []);
			availabilitySlotsDictionary.get(dayString)?.get(timeSlot)?.push(availabilitySlot.employeeId);
		}
	});

	const availableDays: string[] = [];
	availabilitySlotsDictionary.forEach((_, key) => {
		availableDays.push(key);
	});

	const sortedAvailableDaysAsTicks = availableDays.map(day => new Date(day).getTime()).sort();
	const minDay = new Date(sortedAvailableDaysAsTicks[0]);
	const maxDay = new Date(sortedAvailableDaysAsTicks[sortedAvailableDaysAsTicks.length - 1]);

	const nonScheduleableDays: Date[] = [];
	for (let currentDay = new Date(minDay); currentDay <= maxDay; currentDay.setDate(currentDay.getDate() + 1)) {
		const currentDayString = currentDay.toString();
		const existingScheduleableDay = availableDays.find(day => day === currentDayString);
		if (existingScheduleableDay === undefined) {
			nonScheduleableDays.push(new Date(currentDay));
		}
	}

	const tryRenderSlotDropdown = () => {
		if (selectedDay !== undefined) {
			const dayEntry = availabilitySlotsDictionary.get(selectedDay.toString());
			if (dayEntry) {
				const timeFormat: Intl.DateTimeFormatOptions = { hour: "numeric", minute: "numeric", second: undefined };
				const dropdownOptions: IDropdownOption[] = [];
				dayEntry.forEach((_, key) => {
					const timeSplit = key.split('_');
					const startTimeString = new Date(timeSplit[0]).toLocaleTimeString(undefined, timeFormat);
					const endTimeString = new Date(timeSplit[1]).toLocaleTimeString(undefined, timeFormat);
					dropdownOptions.push({ key: key, text: `${startTimeString} - ${endTimeString}` });
				});

				return (
					<Dropdown styles={{label: {color: "white"}}} calloutProps={{calloutMaxHeight: 300}} selectedKey={selectedTimeSlot} onChange={(_, option) => setSelectedTimeSlot(option?.key as string)} options={dropdownOptions} />
				);
			}
		}

		return null;
	};

	const onEmployeeSelected = (selectedEmployee: Employee) => {
		const timeSplit = selectedTimeSlot?.split('_');
		if (timeSplit) {
			employeesContext.setSelectedEmployee(selectedEmployee);
			const startTime = new Date(timeSplit[0]);
			const endTime = new Date(timeSplit[1]);
			employeesContext.setSelectedSlot({
				startAt: startTime,
				endAt: endTime
			});
			const meetingDetailsRoute = routes.meetingDetailsPage.replace(routeParams.employeeId, selectedEmployee.id);
			navigate(meetingDetailsRoute);
		}
	};

	const tryRenderSelectableEmployees = () => {
		if (selectedDay && selectedTimeSlot) {
			const selectableEmployeeIds = availabilitySlotsDictionary.get(selectedDay.toString())?.get(selectedTimeSlot) ?? [];
			const selectableEmployees = employeesContext.employees.filter(employee => selectableEmployeeIds.includes(employee.id));
			return (
				<Stack horizontal wrap horizontalAlign="center" tokens={{ childrenGap: 20 }}>
					{selectableEmployees.map((item) => (
						<AvailableEmployeeCard key={item.id} employee={item} possibleActions={[AvailableEmployeeCardActions.select]} onSelect={onEmployeeSelected} hidePresence />
					))}
				</Stack>
			);
		}

		return null;
	};

	const getSchedulingItem = (iconName: string, label: string, children: JSX.Element | null) => {
		return <Stack horizontal tokens={{ childrenGap: 20 }} className={styles.schedulingItemContainer}>
			<Icon className={styles.icon} iconName={iconName} />
			<Stack className={styles.schedulingItemContent} tokens={{ childrenGap: 10 }}>
				<Text className={styles.title} variant="xLarge">{label}</Text>
				{children}
			</Stack>
		</Stack>
	}

	return (
		<Stack horizontalAlign="center">
			<Stack tokens={{ childrenGap: 20 }}>
				<Stack horizontalAlign="center" tokens={{ childrenGap: 20 }}>
					{getSchedulingItem("CalendarDay", pl.bookMeeting.selectSchedulingDay, <DatePicker
									textField={{className: styles.date}}
									firstDayOfWeek={DayOfWeek.Monday}
									showGoToToday={false}
									formatDate={date => date?.toLocaleDateString() ?? ""}
									value={selectedDay}
									onSelectDate={date => setSelectedDay(date ?? minDay)}
									initialPickerDate={minDay}
									minDate={minDay}
									maxDate={maxDay}
									calendarProps={{ restrictedDates: nonScheduleableDays }}
									strings={datepickerStrings}
								/>)}
					{selectedDay !== undefined && getSchedulingItem("DateTime", pl.bookMeeting.selectSchedulingSlot, tryRenderSlotDropdown())}
				</Stack>
				{tryRenderSelectableEmployees()}
			</Stack>
		</Stack>
	);
};
