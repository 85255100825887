import React, { FunctionComponent } from "react";
import styles from "./checkbox.module.scss";
import classNames from "classnames";
import { CheckboxProps } from "./Checkbox.types";

export const Checkbox: FunctionComponent<CheckboxProps> = (props: CheckboxProps) => {
  return (
    <div className={styles.wrapper}>
      <input
        type="checkbox"
        onChange={props.onCheckedChanged}
        className={classNames(styles.checkbox, styles.checkboxSize)}
        checked={props.checked}
        disabled={props.disabled}
      />
      <svg
        viewBox="0 0 21 21"
        className={classNames(styles.tickMark, styles.checkboxSize)}
      >
        <polyline points="5 10.75 8.5 14.25 16 6" />
      </svg>
    </div>
  );
};