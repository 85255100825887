import { ISettings } from "../ISettings";

const defaultSettings: ISettings = {
    logoFileName: "logo.png",
    publicFolder: "/images/vanilla",
    baseApiUrl: "",
    applicationInsights: {
        cloudRoleName: "Customers",
        connectionString : ""
    }
}

export default defaultSettings;