import { Text } from "@fluentui/react";
import classNames from "classnames";
import styles from "./title.module.scss";

export interface TitleProps {
	content: string;
	large?: boolean;
	additionalClassName?: string;
};

export const Title: React.FC<TitleProps> = (props: TitleProps) => {
	const variant = props.large ? "superLarge" : "xxLarge";
	return (
		<Text className={classNames(styles.title, props.additionalClassName)} variant={variant}>{props.content}</Text>
	)
};
