import { IconButton, IIconProps, Modal, Stack } from "@fluentui/react";
import { ReactNode, useState } from "react";
import styles from "./modalPopup.module.scss";

export interface ModalPopupProps {
  children: ReactNode;
  closeable?: boolean;
  onClose?: () => void;
}

export const ModalPopup: React.FC<ModalPopupProps> = (
  props: ModalPopupProps
) => {
  const [isOpen, setIsOpen] = useState(true);
  const cancelIcon: IIconProps = { iconName: "Cancel" };

  return (
    <Modal containerClassName={styles.modal} isOpen={isOpen}>
      <Stack>
        {props.closeable && (
          <IconButton
            className={styles.cancelIcon}
            iconProps={cancelIcon}
            onClick={() => {
              setIsOpen(false);
              if (props.onClose) {
                props.onClose();
              }
            }}
          />
        )}
        <Stack
          className={styles.content}
          verticalAlign="center"
          horizontalAlign="center"
        >
          {props.children}
        </Stack>
      </Stack>
    </Modal>
  );
};
