import { Stack, Text } from "@fluentui/react";
import pl from "@messages/pl";
import styles from "./GlobalNotFound.module.scss";

const GlobalNotFound: React.FC = () => (
    <Stack verticalAlign="center" horizontalAlign="center">
        <Text variant="xxLarge" className={styles.text}>{pl.common.pageNotFound}</Text>
    </Stack>
);

export default GlobalNotFound;