import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { ApplicationInsights as AI } from "./ApplicationInsights"; 
import defaultSettings from "@appSettings/Settings";

const browserHistory = createBrowserHistory({ window: window });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: defaultSettings.applicationInsights.connectionString,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }, 
        enableCorsCorrelation: true
    }
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer(telemetryItem => {
    if (telemetryItem.tags) {
        telemetryItem.tags['ai.cloud.role'] = defaultSettings.applicationInsights.cloudRoleName;
    }
});
export { reactPlugin, appInsights, AI as ApplicationInsights };