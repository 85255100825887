import React from "react";
import { Metadata } from "../models";

export interface MetadataContextProps {
  metadata: Metadata;

  fetchingMetadata: boolean;
  fetchMetadata: () => Promise<void>;
}

export const defaultMetadataContext: MetadataContextProps = {
  metadata: {
    featureFlags: {
      callCenterEnabled: true,
      callNowMeetingsEnabled: true,
      acceptanceRecordingConsentBeforeJoinMeetingEnabled: true,
      scheduledMeetingsEnabled: true,
    },
    consents: [],
  },

  fetchingMetadata: false,
  fetchMetadata: () => new Promise<void>((_) => null),
};

export const MetadataContext = React.createContext<MetadataContextProps>(
  defaultMetadataContext
);
