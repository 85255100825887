import React from "react";

export interface QueryParamsContextProps {
    isEmbedded: boolean;
    setQueryContext: () => void;
};

export const QueryParamsContext = React.createContext<QueryParamsContextProps>({
    isEmbedded: false,
    setQueryContext: () => {}
});
