import { Image } from "@fluentui/react";
import styles from "./AppLogo.module.scss";
import defaultSettings from "@appSettings/Settings";
import { useNavigate } from "react-router";

const logoUrl = `${defaultSettings.publicFolder}/${defaultSettings.logoFileName}`;

export const AppLogo: React.FC = () => {

    const navigation = useNavigate();

    return <Image className={styles.appLogo} src={logoUrl} onClick={() => navigation("/")} />;
};
