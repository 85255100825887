import React from "react";

export interface GlobalContextProps {
    appLoading: boolean;
    appLoadingMessage?: string;
    setAppLoading: (isLoading: boolean, loadingMessage?: string) => void;

    appError: string;
    setAppError: (errorMessage?: string) => void;
};

export const GlobalContext = React.createContext<GlobalContextProps>({
    appLoading: true,
    appLoadingMessage: undefined,
    setAppLoading: _ => null,
    
    appError: "",
    setAppError: _ => null
});
